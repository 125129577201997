import * as moment from "moment";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useParams } from "react-router-dom";

import Money from "../../../components/Common/Money";
import ContentLoader from "../../../components/Common/ContentLoader";
import { useBOfficeGet } from "../BackofficeHooks";
import MoneyAmount from "../MoneyAmount/MoneyAmount";

const columns = [
  {
    name: "ID",
    selector: "id",
    cell: (row) => <small>{row.id}</small>,
  },
  {
    name: "Acc ID",
    selector: "accountId",
    cell: (row) => <small>{row.accountId}</small>,
    sortable: true,
  },

  {
    name: "Type",
    selector: "type",
    wrap: true,
    sortable: true,
  },

  {
    name: "Status",
    selector: "status",
    wrap: true,
    sortable: true,
  },
  {
    name: "Amount",
    selector: "amount",
    right: true,
    wrap: true,
    sortable: true,
    format: (row) => <Money value={row.amount} />,
  },
  {
    name: "Created On",
    selector: "createdOn",
    right: true,
    wrap: true,
    sortable: true,
    format: (row) => <ADate value={row.createdOn} />,
  },
  {
    name: "Scheduled On",
    selector: "scheduledAt",
    right: true,
    wrap: true,
    sortable: true,
    format: (row) => <ADate value={row.scheduledAt} />,
  },
  {
    name: "Status Upadated On",
    selector: "statusUpdatedOn",
    right: true,
    wrap: true,
    format: (row) => <ADate value={row.statusUpdatedOn} />,
  },
  {
    name: "Order Status",
    selector: "orderStatus",
    wrap: true,
    sortable: true,
  },
  {
    name: "Order Date",
    selector: "orderDate",
    right: true,
    wrap: true,
    format: (row) => <ADate value={row.orderDate} />,
  },
];

const ClientDetails = () => {
  const [client, setClient] = useState(null);
  const [times, setTimes] = useState(null);
  const [transactions, setTransactions] = useState(null);
  const [clients, loading] = useBOfficeGet();
  const [getTx, loadingTx] = useBOfficeGet();
  const { id } = useParams();

  useEffect(() => {
    clients(`/backoffice/clients/${id}`).then((resp) => {
      if (!resp) return;

      getTx(`/backoffice/clients/${id}/transactions`).then((tx) => {
        if (!tx) return;

        setTransactions(tx.data.transactions);
      });

      setClient(resp.data.details);
      setTimes(resp.data.timestamps);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!!client && (
        <ContentLoader loading={loading || loadingTx}>
          <div className="">
            <h3 className="pl-1">
              {client.firstName} {client.lastName}
            </h3>

            <div className="d-flex flex-wrap mt-3">
              <Detail label="First Name">{client.firstName}</Detail>
              <Detail label="Last Name">{client.lastName}</Detail>
              <Detail label="Mobile Phone">{client.mobilePhone}</Detail>
              <Detail label="Email">{client.email}</Detail>
              <Detail label="Residency Country">
                {client.residencyCountry}
              </Detail>
              <Detail label="Nationality Country">
                {client.nationalityCountry}
              </Detail>
              <Detail label="Baader ID">{client.bbid}</Detail>
              <Detail label="Innitial Investment Settings">
                <div className="d-flex justify-content-between mt-2">
                  <small>Initial: </small>
                  <MoneyAmount amount={client.initialInvestmentAmount} />
                </div>
                <div className="d-flex justify-content-between">
                  <small>Monthly: </small>
                  <MoneyAmount amount={client.initialMonthlyInvestmentAmount} />
                </div>
              </Detail>
              <Detail label="Affiliate">{client.affiliate}</Detail>
              <Detail label="Baader IBAN">{client.bbIBAN}</Detail>
              <Detail label="Reference IBAN">{client.referenceIBAN}</Detail>
              <Detail label="Last Sync (to BB servers)">
                {client.lastAccountSyncDate ? (
                  <ADate value={client.lastAccountSyncDate} />
                ) : (
                  "---"
                )}
              </Detail>
              <Detail label="Current Product">{client.product}</Detail>
              <Detail label="Current Balance">
                <MoneyAmount amount={client.cashBalance} />
              </Detail>
              <Detail label="Total AUM">
                <MoneyAmount amount={client.totalAUM} />
                <span>{` (${client.aumPercentageNetWorth})`}</span>
              </Detail>

              <Detail label="Current Monthly Plan">
                <MoneyAmount amount={client.currentMonthlyPlan} />
                <span>{` (${client.monthlySavingsPercentage})`}</span><br />
                {
                  client.monthlyPlanDayOfMonth && <span>({`Date: ${client.monthlyPlanDayOfMonth}`})</span>
                }
              </Detail>
              <Detail label="Profit percentage">
                {client.profitPercentage}%
              </Detail>
              <Detail label="Profit € amount">
                <MoneyAmount amount={client.profitEarnings} />
              </Detail>
              <Detail label="Address">{client.address}</Detail>
            </div>

            {!!times && (
              <>
                <h3 className="pl-1 mt-5">Timestamps</h3>
                <div className="d-flex flex-wrap mb-5">
                  <Detail label="Onboarding Started">
                    <ADate value={times.onboardingStart} />
                  </Detail>
                  <Detail label="Reminder 1">
                    <ADate value={times.onboardingReminder1} />
                  </Detail>
                  <Detail label="Reminder 2">
                    <ADate value={times.onboardingReminder2} />
                  </Detail>
                  <Detail label="KYC Finished">
                    <ADate value={times.kycFinished} />
                  </Detail>
                  {/* <Detail label="Sent to BB">
                    <ADate value={times.bbOpen} />
                  </Detail> */}
                  <Detail label="BB Open">
                    <ADate value={times.bbOpen} />
                  </Detail>
                  <Detail label="SEPA Sent">
                    <ADate value={times.firstSEPASubmission} />
                  </Detail>
                  <Detail label="SEPA arrive">
                    <ADate value={times.firstSEPAArrival} />
                  </Detail>
                  <Detail label="Money Invested">
                    <ADate value={times.firstOrderExecution} />
                  </Detail>
                  <Detail label="Dashboard Unlocked">
                    <ADate value={times.firstOrderExecution} />
                  </Detail>
                </div>
              </>
            )}

            {!!transactions && (
              <>
                <DataTable
                  striped
                  defaultSortField="createdOn"
                  defaultSortAsc={false}
                  title="Transactions"
                  columns={columns}
                  data={transactions}
                  responsive
                />
              </>
            )}
          </div>
        </ContentLoader>
      )}
    </>
  );
};

const ADate = ({ value }) => {
  if (!value) return <span>---</span>;
  return <span>{moment(value).format("DD.MM.YYYY")}</span>;
};

const Detail = ({ label, children }) => {
  return (
    <div
      className="mx-1 my-2 px-2 py-1 rounded"
      style={{
        backgroundColor: "#eee",
      }}
    >
      <small>{label}</small>
      <div>{children}</div>
    </div>
  );
};

export default ClientDetails;
